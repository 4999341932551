<template>
  <div>
    <v-row no-gutters justify="space-between">
      <div class="text-header">How it works</div>
      <v-btn
        text
        @click="
          $router.push({
            name: 'Calculator',
          })
        "
        >Skip</v-btn
      >
    </v-row>

    <v-expansion-panels v-model="activeOnboardingStep">
      <!-- step 1 -->
      <ExpandableCard
        :number="0"
        title="Calculate your CO2 emissions"
        description="Answer a few questions about yourself to tell us where you live, how
          you travel and what you get up to."
      />
      <!-- step 2 -->
      <ExpandableCard
        :number="1"
        title="Pick your Zero plan"
        description="You can either simply offset your own carbon emissions, or go
          full-Greta and offset even more!"
      />

      <!-- step 3 -->
      <ExpandableCard
        :number="2"
        title="Set up your payment"
        description="Sync your banking app with Zero for automatic payments - or pay with
          Apple Pay, Google Pay or card."
      />

      <!-- step 4 -->
      <ExpandableCard
        :number="3"
        title="Show the world you’re zero carbon"
        description=" Not many people can say they’re zero-carbon. So make sure you feel
          proud and shout about it."
        isLastStep
      />
    </v-expansion-panels>
    <div class="text-center my-8" v-if="!getCurrentUser">
      <span> Already have an account? </span>
      <span class="text-underline" @click="$router.push({ name: 'SignIn' })"
        >Log in here</span
      >
    </div>
  </div>
</template>
<script>
import ExpandableCard from "@/components/onBoarding/ExpandableCard.vue";
import onBoardingMixin from "@/mixins/onBoardingMixin.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: { ExpandableCard },
  mixins: [onBoardingMixin],
  created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Get started");
  },
  data() {
    return {
      currentStep: 1,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
  },
  methods: {
    ...mapMutations(["setCurrentAppBarTitle"]),
  },
};
</script>
