<template>
  <v-expansion-panel class="my-2 elevation-0" style="border-radius: 8px">
    <v-expansion-panel-header hide-actions>
      <v-row justify="start">
        <v-col cols="auto">
          <span class="step-number">Step {{ number + 1 }} </span>
        </v-col>
        <v-col>
          <div v-if="activeOnboardingStep != number" class="step-title">
            {{ title }}
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="activeOnboardingStep == number" class="step-title mb-2">
        {{ title }}
      </div>

      <div>{{ description }}</div>
      <v-row class="col-12" no-gutters justify="end">
        <v-btn
          v-if="isLastStep"
          class="cta-button text-capitalize"
          dark
          depressed
          rounded
          @click="
            $router.push({ name: `Calculator` }, () => {});
            goFullScreen();
          "
        >
          <span>Get Started</span>
        </v-btn>
        <v-btn
          v-else
          class="cta-button text-capitalize"
          dark
          @click="activeOnboardingStep++"
          depressed
          rounded
        >
          <span>Next</span>
        </v-btn>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import onBoardingMixin from "@/mixins/onBoardingMixin.vue";
// import { api as fullscreen } from "vue-fullscreen";

// todo : add animation in routes navigation
export default {
  data() {
    return {};
  },
  mixins: [onBoardingMixin],

  props: {
    number: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    goFullScreen() {
      // fullscreen.toggle();
    },
  },
};
</script>
<style scoped>
.step-number {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
}
.step-title {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
}
.step-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
}
.v-expansion-panel::before {
  box-shadow: 0px 8px 16px rgba(91, 91, 91, 0.16);
}
.v-expansion-panel:not(:first-child)::after {
  border-top-width: 0px;
}
</style>