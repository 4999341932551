
<script>
export default {
  computed: {
    activeOnboardingStep: {
      get: function () {
        return this.$store.getters["activeOnboardingStep"];
      },
      set: function (newVal) {
        this.$store.commit("activeOnboardingStep", newVal);
      },
    },
  },
};
</script>
